export const ddtMixin = {
  methods: {
    processDdtForMail (ddt) {
      ddt.voci = []
      if (ddt.raggruppati) {
        ddt.raggruppati.forEach(function (c) {
          let voce = { codice: c.codice, qta: c.qta, um: 'NR' }
          voce.descr = ''
          if (c.opera) voce.descr = `<div>${c.opera}</div>`
          if (c.componente) voce.descr += `<div>${c.componente}</div>`
          if (c.descrizione) voce.descr += `<div>${c.descrizione}</div>`
          if (c.tipo_lavorazione) voce.descr += `<div>${c.tipo_lavorazione}</div>`
          ddt.voci.push(voce)
        })
      } else {
        ddt.bancali.forEach(function (b) {
          b.componenti.forEach(function (c) {
            let voce = { codice: c.codice, qta: c.qta, um: 'NR' }
            voce.descr = ''
            if (c.opera) voce.descr = `<div>${c.opera}</div>`
            if (c.componente) voce.descr += `<div>${c.componente}</div>`
            if (c.descrizione) voce.descr += `<div>${c.descrizione}</div>`
            if (c.tipo_lavorazione) voce.descr += `<div>${c.tipo_lavorazione}</div>`
            ddt.voci.push(voce)
          })
        })
      }
      if (ddt.merce && ddt.merce.length) {
        ddt.merce.forEach(function (m) {
          let voce = { codice: m.codice, qta: m.qta, um: m.unita_misura, descr: m.descrizione }
          ddt.voci.push(voce)
        })
      }

      return ddt
    }
  }
}
