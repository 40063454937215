<template>
  <v-dialog
    v-model="ddt"
    fullscreen
    transition="dialog-bottom-transition"
    :overlay="false"
    scrollable>
    <v-card>
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>
          {{ modalTitle() }}
          <item-updated
            v-if="ddt._id"
            :mdate="ddt.modifica"
            :muser="ddt.utente" />
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$router.push({ name: 'ddtsm' })"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-stepper non-linear light v-model="step">
          <v-stepper-header v-show="ddt._id">
            <template v-for="(step, index) in steps">
              <v-stepper-step
                color="primary"
                :key="index + 'step'"
                :step="step.number"
                @click.native="changeStep(index, $event)"
                editable>{{ step.name }}</v-stepper-step>
              <v-divider :key="index + 'divider'" />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <!-- primo step direttamente qui -->
              <ddt-scheda ref="scheda" :ddt="ddt" />
            </v-stepper-content>
            <v-stepper-content v-for="(s, index) in steps" :key="index" :step="s.number" v-if="s.number > 1">
              <!-- gli altri step sono rotte figlie -->
              <router-view v-if="s.number === step" :ddt="ddt" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions v-if="ddt._id">
        <v-container fluid>
          <v-layout>
            <v-flex>
              <template v-for="(s, index) in steps">
                <div v-if="s.number === step" :key="index">
                  <span class="mr-4" v-if="steps[index - 1]">
                    <v-btn color="primary" small fab depressed dark @click="changeStep(index - 1)">
                      <v-icon>chevron_left</v-icon>
                    </v-btn>
                    <span class="body-2 hidden-xs-only">
                      {{ steps[index - 1].name }}
                    </span>
                  </span>
                  <span class="ml-4" v-if="steps[index + 1]">
                    <span class="body-2 hidden-xs-only">
                      {{ steps[index + 1].name }}
                    </span>
                    <v-btn color="primary" small fab depressed dark @click="changeStep(index + 1)">
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </span>
                </div>
              </template>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from '@/eventBus'
import itemUpdated from '@/components/common/ItemUpdated'
import ddtScheda from '@/components/magazzino/ddt/DdtScheda'

export default {
  data: () => ({
    ddt: {},
    step: 1,
    steps: [
      { number: 1, name: 'Informazioni e destinazione', route: 'ddtm' },
      { number: 2, name: 'Merce', route: 'ddtmMerce' }
    ]
  }),
  components: {
    itemUpdated,
    ddtScheda
  },
  methods: {
    modalTitle () {
      let tit = this.ddt._id ? this.ddt.progressivo + '.' + this.ddt.esercizio : 'Nuovo ddt'
      return tit
    },
    changeStep (index) {
      this.$router.push({ name: this.steps[index].route, params: { id: this.ddt._id } }) // prima vai in modo che la rotta di arrivo inizi già a leggere i dati
      this.step = this.steps[index].number
      /* this.$plsqlSave('ddtm', this.ddt)
        .then((response) => { // poi salva e cambia step
          this.step = this.steps[index].number
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', {
            type: 'error',
            text: 'Errore salvataggio ddt'
          })
        }) */
    }
  },
  mounted () {
    if (this.$route.params.id !== 'add') {
      this.$plsqlRead('ddtm', this.$route.params.id)
        .then(response => {
          this.ddt = response.data
          this.$refs.scheda.getClienti(response.data._id)
          if (this.$route.name !== 'ddtm') { // arrivo diretto al path di uno step
            this.step = this.steps.find(step => step.route === this.$route.name).number
          }
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', {
            type: 'error',
            text: 'Errore lettura DDT'
          })
        })
    } else {
      this.ddt = {
        causale: this.$route.params.causale,
        data: new Date(Date.now()).toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).slice(0, 10),
        inizio_trasporto: new Date(Date.now()).toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).slice(0, 10)
      }
      this.$refs.scheda.getClienti()
    }
    $EventBus.$on('ddt:added', (ddt) => {
      this.ddt = ddt
      this.$router.push({ name: 'ddtm', params: { id: ddt._id } })
    })
  }
}
</script>
